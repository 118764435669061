<template>
  <div class="flex gap-x-4">
    <go-back :title="`Avisos de pago: ${id}`"></go-back>
    <span
      class="text-secondary-blue font-semibold cursor-pointer"
      @click="goEdit"
      >Editar</span
    >
  </div>

  <div class="mt-4 form-table w-full md:max-w-screen-md text-sm">
    <div class="row">
      <div>Aviso:</div>
      <div class="value">{{ aviso.aviso }}</div>
    </div>

    <div class="row">
      <div>Cargado por:</div>
      <div class="value">{{ clients[aviso.created_by] || aviso.created_by }}</div>
    </div>

    <div class="row">
      <div>Fecha de creación:</div>
      <div class="value">{{ aviso.fecha }}</div>
    </div>

    <div class="row">
      <div>Fecha del pago:</div>
      <div class="value">{{ aviso.fechaPago }}</div>
    </div>

      <div class="row">
        <div>Estado:</div>
        <div class="value relative">
          {{estados[aviso.estado] || null}}
        </div>
      </div>

    <div class="row">
      <div>Comprobantes imputados:</div>
      <div class="value">
        <div v-for="(comprobante, index) in aviso.comprobantes" :key="index">
          {{ comprobante }}
        </div>
      </div>
    </div>

    <div class="row">
      <div>Importe del pago:</div>
      <div class="value">USD {{ aviso.importePago }}</div>
    </div>

    <div class="row">
      <div>Tipo de cambio:</div>
      <div class="value">{{ aviso.tipoCambio }}</div>
    </div>

    <template v-for="formaPago in formasDePago" :key="formaPago.id">
      <div
        class="row"
        v-for="(item, index) in aviso[formaPago.id]"
        :key="index"
      >
        <form-pago
          :readOnly="true"
          :titulo="`${formaPago.texto} ${index + 1}`"
          :tipo="item.tipo"
          :importe="item.importe"
          :moneda="item.moneda"
          :numero="item.numero"
          :emision="item.emision"
          :pago="item.pago"
          :agencia="item.agencia"
          :banco="item.banco"
          :sucursal="item.sucursal"
          :file="item.file"
          :bancos="bancos"
          :sucursales="sucursales"
          :agencias="agencias"
          :monedas="monedas"
        >
        </form-pago>
      </div>
    </template>

    <div class="row">
      <div>Doccabid (SIGE):</div>
      <div class="value">{{ aviso.doccabid }}</div>
    </div>

    <div class="row">
      <div>Recibo:</div>
      <div class="value">{{ aviso.recibo }}</div>
    </div>

    <div class="row">
      <div>Comentarios:</div>
      <div class="value">{{ aviso.mensaje }}</div>
    </div>

    <div class="mt-4 font-semibold">Mensajes:</div>
    <div
      v-for="mensaje in aviso.mensajes"
      :key="mensaje.message_id"
      class="w-full row grid grid-cols-1 mobile:grid-cols-4"
    >
      <div class="flex flex-col mobile:flex-col order-2 mobile:order-none">
        <p class="text-xs">{{ mensaje.sender }}</p>
        <p class="text-xs">{{ mensaje.ts }}</p>
      </div>
      <div class="value order-1 mobile:order-none">{{ mensaje.comments }}</div>
    </div>

    <textarea
      name="mensajes"
      id="mensajes"
      rows="5"
      :disabled="cargandoMensaje"
      v-model="nuevoMensaje"
      class="mt-4 w-full mobile:w-2/3 border border-main-blue rounded"
    ></textarea>

    <div>
      <button-blue :disabled="cargandoMensaje" @click="enviarMensaje">
        ENVIAR MENSAJE
      </button-blue>

      <div class="mt-2">
        <panel-avisos-generar-recibo :formId="id" :statusId="aviso.estado" />
      </div>

      <div
        class="mt-2 flex items-center text-secondary-blue cursor-pointer"
        @click="$router.go(-1)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
            clip-rule="evenodd"
          />
        </svg>
        Volver
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from "vue";
import { format, parseISO } from "date-fns";
import {
  apiClientPayments,
  apiGetMessages,
  apiGetFilters,
  apiPaymentMethods,
  apiGetAgencias,
} from "@/router/endpoints";
import { stringToDate } from "@/Functions";
import { formatBancos, getBancoId } from "@/FormatFeli";
import axios from "axios";
import router from "@/router";

import GoBack from "@/components/GoBack.vue";
import ButtonBlue from "./ButtonBlue.vue";
import PanelAvisosGenerarRecibo from "./PanelAvisosGenerarRecibo.vue";
import FormPago from "@/components/PanelAvisosFormPago.vue";

export default defineComponent({
  components: {
    GoBack,
    ButtonBlue,
    PanelAvisosGenerarRecibo,
    FormPago,
  },
  setup() {
    const id = router.currentRoute.value.params.id.toString();

    let aviso = reactive({
      estado: "",
      fecha: "",
      fechaPago: "",
      importePago: "",
      comprobantes: "",
      mensaje: "",
      mensajes: "",
      cheque: [],
      giro: [],
      efectivo: [],
      tarjeta: [],
    });

    const formasDePago = ref([
      {
        idBack: 0,
        id: "cheque",
        textoBack: "Cheque",
        texto: "Cheque",
      },
      {
        idBack: 0,
        id: "giro",
        textoBack: "Giro Bancario",
        texto: "Giro Bancario",
      },
      {
        idBack: 0,
        id: "tarjeta",
        textoBack: "Tarjeta",
        texto: "Tarjeta",
      },
      {
        idBack: 0,
        id: "efectivo",
        textoBack: "Efectivo",
        texto: "Efectivo",
      },
    ]);

    // Bancos, Sucursales, $ a USD, formasDePago
    let bancos = ref([]);
    let sucursales = reactive({});
    let monedas = ref([]);
    const getPaymentMethods = () => {
      return axios.get(apiPaymentMethods()).then((res) => {
        aviso.tipoCambio = parseFloat(res.data.usd_rate);

        let dataBancos = res.data.banks;
        let { bancos: fBancos, sucursales: fSucursales } =
          formatBancos(dataBancos);
        bancos.value.push(...fBancos);
        Object.assign(sucursales, fSucursales);

        // Linkeo id de back con objeto de front
        let dataFormasPago = res.data.payment_methods;
        Object.keys(dataFormasPago).map((key) => {
          let strForma = dataFormasPago[key];
          formasDePago.value.find((f) => f.textoBack === strForma).idBack = key;
        });

        let dataCurrency = res.data.corrency_types.map((item) => {
          return {
            value: item.id.toString(),
            text: item.name,
            rate: item.rate,
          };
        });
        monedas.value.push(...dataCurrency);
      });
    };

    // Agencias
    let agencias = ref([]);
    const getAgencias = () => {
      axios.get(apiGetAgencias()).then((res) => {
        res = res.data.map((item) => {
          return {
            value: item.agencia_id,
            text: item.agencia,
          };
        });
        agencias.value.push(...res);
      });
    };

    // Clientes y Estados
    let estados = reactive({});
    let clients = reactive({});
    axios.get(apiGetFilters()).then((res) => {
      let statusesObj = res.data["statuses"];
      Object.assign(estados, statusesObj);

      let clientsObj = res.data["clients"];
      Object.assign(clients, clientsObj);
    });

    // Init de datos
    const getDetalle = () => {
      axios.get(apiClientPayments(id)).then((res) => {
        res = res.data;
        Object.assign(aviso, {
          aviso: res.payment,
          created_by: res.created_by,
          estado: res.status,
          fecha: res.date ? format(parseISO(res.date), "dd/MM/yyyy") : null,
          fechaPago: res.payment_date
            ? format(parseISO(res.payment_date), "dd/MM/yyyy")
            : null,
          importePago: res.total_amount,
          comprobantes: res.invoices,
          mensaje: res.comments,
          mensajes: res.messages,
          payments: res.payments,
          doccabid: res.doccabid,
          recibo: res.recibo_id,
          cheque: [],
          giro: [],
          efectivo: [],
          tarjeta: [],
          tipoCambio: res.usd_rate,
        });

        // Crea los elementos de pago
        aviso.payments.forEach((payment) => {
          let idTipo = formasDePago.value.find(
            (f) => f.idBack == payment.tipo
          ).id;
          addPago(idTipo, { ...payment });
        });
      });
    };

    const init = async () => {
      getAgencias();

      // Await necesario para linkear los ids
      await Promise.all([getPaymentMethods()]);
      getDetalle();
    };
    init();

    // Creación de nuevo elemento de pago
    const addPago = (tipo, precarga = {}) => {
      aviso[tipo].push({
        tipo: tipo,
        idTipo: formasDePago.value.find((f) => f.id === tipo).idBack,
        id: precarga.id || "",
        importe: precarga.importe || 0,
        moneda:
          precarga.moneda !== undefined
            ? precarga.moneda.toString()
            : monedas.value.find((m) => m.text.toLowerCase() == "usd").value,
        numero: precarga.nro_cheque ? parseInt(precarga.nro_cheque) : 0,
        emision: precarga.fecha_emision
          ? stringToDate(precarga.fecha_emision)
          : "",
        pago: precarga.fecha_pago ? stringToDate(precarga.fecha_pago) : "",
        agencia: precarga.agencia_id?.toString() || "",
        banco: precarga.banco_id
          ? getBancoId(sucursales, precarga.banco_id)
          : "",
        sucursal: precarga.banco_id?.toString() || "",
        file: precarga.file_id || "",
      });
    };

    // Manejo de mensajes
    let cargandoMensaje = ref(false);
    let nuevoMensaje = ref("");
    const enviarMensaje = () => {
      cargandoMensaje.value = true;
      if (nuevoMensaje.value) {
        axios
          .post(apiGetMessages(id), {
            comments: nuevoMensaje.value,
          })
          .then(() => {
            nuevoMensaje.value = "";
            getDetalle();
          })
          .catch((err) => {
            console.log("Ha ocurrido un error:", err);
          })
          .finally(() => {
            cargandoMensaje.value = false;
          });
      }
    };

    // Router
    const goEdit = () => {
      router.push({ name: "avisos-de-pago-editar", params: { id: id } });
    };

    return {
      id,
      aviso,
      enviarMensaje,
      nuevoMensaje,
      cargandoMensaje,
      goEdit,
      estados,
      clients,
      formasDePago,
      bancos,
      agencias,
      sucursales,
      monedas,
    };
  },
});
</script>
