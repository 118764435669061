<template>
  <button-blue
    :loading="loadingRecibo"
    :disabled="loadingRecibo"
    @click="generarRecibo"
    class="flex items-center"
  >
    <div v-if="status == statusRecibidoGenerado">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
          clip-rule="evenodd"
        />
      </svg>
    </div>

    {{ status == statusRecibidoGenerado ? "Recibo PDF" : "GENERAR RECIBO" }}
  </button-blue>

  <a id="descargaComprobante" href="" hidden></a>
</template>

<script>
import { defineComponent, ref, toRef } from "vue";
import axios from "axios";
import { showToast, showError } from "@/Functions";
import { apiGenerarSige, apiGetReciboPdf } from "@/router/endpoints";
import ButtonBlue from "./ButtonBlue.vue";

export default defineComponent({
  components: { ButtonBlue },
  props: {
    formId: {
      type: String,
      required: true,
    },
    statusId: {
      type: [String, Number],
      required: true,
    },
  },
  setup(props) {
    let formId = toRef(props, "formId");
    let status = toRef(props, "statusId");
    let statusRecibidoGenerado = 159;

    let loadingRecibo = ref(false);

    const generarRecibo = () => {
      loadingRecibo.value = true;
      const link = document.getElementById("descargaComprobante");

      if (status.value == statusRecibidoGenerado) {
        axios
          .get(apiGetReciboPdf(formId.value), { responseType: "blob" })
          .then((response) => {
            let objectURL = URL.createObjectURL(response.data);
            link.href = objectURL;
            link.download = "comprobante-"+formId.value+".pdf";
            link.click();
          })
          .catch((err) => {
            showError(err);
          })
          .finally(() => {
            loadingRecibo.value = false;
          });
      } else {
        axios
          .get(apiGenerarSige(formId.value))
          .then(() => {
            showToast("Comprobante generado con éxito", "success");
            status.value = statusRecibidoGenerado;
          })
          .catch((err) => {
            showError(err);
          })
          .finally(() => {
            loadingRecibo.value = false;
          });
      }
    };

    return { generarRecibo, loadingRecibo, statusRecibidoGenerado, status };
  },
});
</script>
